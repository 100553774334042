<template>
  <div>
    <div class="setting-nav-title">
      <!-- {{ $t("tradeTypeManage") }} -->
      <!-- <el-button class="confirm pull-right" @click="applyNewPayment">{{ $t("newpayment") }}</el-button> -->
    </div>
    <div style="margin-bottom: 20px;">
      <el-button @click="createTenantDialog = true">CreateTenantType</el-button>
    </div>
    <div class="payment-container" v-loading="loading">
      <nodata v-if="tenantList.length === 0" />
      <el-table v-else :data="tenantList">
        <el-table-column prop="id" label="id"></el-table-column>
        <el-table-column prop="typeName" label="typeName"></el-table-column>
        <el-table-column prop="remark" label="remark"></el-table-column>
        <el-table-column fixed="right" label="" width="200">
          <template slot-scope="scope">
            <el-button @click="eTenant(scope.row)" type="text" size="small">EDIT</el-button>
            <el-button @click="dTenant(scope.row)" type="text" size="small">DELETE</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="" :visible.sync="createTenantDialog" v-loading="loading" @close="handelDialogClose">
      <el-form :model="form" ref="form">
        <el-form-item label="typeName" prop="typeName" required>
          <el-input v-model="form.typeName"></el-input>
        </el-form-item>
        <el-form-item label="remark" prop="remark" required>
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-button @click="cTenant()">Submit</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import paymentusing from "@/assets/using.png";
import paymentchecking from "@/assets/checking.png";
import paymentappling from "@/assets/appling.png";
import paymentfailed from "@/assets/failed.png";
import { getTenants, createTenant, delTenant, updateTenant, getPaymentClasses, getPaymentTypes } from "api/tenant";
import {
  getTenantConfigType,
  createTenantConfigType,
  deleteTenantConfigType,
  updateTenantConfigType
} from "api/tenant";
import { getBigPayLogoByTradeTypeName } from "utils/tradeTypeLogo";
export default {
  data() {
    return {
      loading: false,
      createTenantDialog: false,
      paymentusing,
      paymentchecking,
      paymentappling,
      paymentfailed,
      paylist: [],
      tenantList: [],
      paymentClassList: [],
      paymentTypeList: [],
      form: {
        id: 0,
        typeName: "string",
        remark: "string"
      }
    };
  },
  mounted() {
    this.gTenant();
    // let pc = getPaymentClasses().then(res => {
    //   console.log(res);
    //   if (res.statusCode == 200) {
    //     this.paymentClassList = res.result;
    //   }
    // });
    // let pt = getPaymentTypes().then(res => {
    //   console.log(res);
    //   if (res.statusCode == 200) {
    //     this.paymentTypeList = res.result;
    //   }
    // });
  },
  methods: {
    handelDialogClose() {
      this.form = {
        id: 0,
        typeName: "string",
        remark: "string"
      };
      this.$refs.form.resetFields();
    },
    gTenant() {
      return getTenantConfigType({ pageSize: 9999 }).then(res => {
        console.log(res);
        if (res.statusCode === 200 && res.resultStatus === "Success") {
          this.tenantList = res.result;
        }
      });
    },
    cTenant() {
      this.$refs.form.validate(valid => {
        console.log(valid);
        if (valid) {
          this.loading = true;
          let xhr;

          if (this.form.id > 0) {
            xhr = updateTenantConfigType(this.form);
          } else {
            xhr = createTenantConfigType(this.form);
          }

          xhr
            .then(res => {
              this.loading = false;
              console.log(res);
              if (res.statusCode == 200) {
                // this.tenantList = res.result;
                this.createTenantDialog = false;
                this.gTenant();
              }
            })
            .catch(err => {
              this.loading = false;
            });
        }
      });
    },
    dTenant(row) {
      this.$confirm("Confirm?", "warning", {
        type: "warning"
      })
        .then(() => {
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
          this.loading = true;

          deleteTenantConfigType({ id: row.id })
            .then(res => {
              console.log(res);
              this.loading = false;
              if (res.statusCode === 200 && res.resultStatus === "Success") {
                this.gTenant().then(res => {
                  console.log(res);
                  this.loading = false;
                });
              }
            })
            .catch(err => {
              this.loading = false;
            });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    eTenant(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.createTenantDialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.notification-container {
  margin: 16px 0;
}

.payment-container {
  background: #fffffe;
  box-shadow: 0 2px 12px 0 rgba(103, 95, 119, 0.1);
  border-radius: 8px;
  padding: 0 24px;
}

.payment-item {
  @include flex();

  padding: 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  &:last-child {
    border: none;
  }

  .payment-img-container {
    width: 122px;
    height: 80px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    position: relative;
    border-radius: 8px;

    & > img {
      width: 90px;
      height: 54px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .payment-content {
    flex: 1;
    padding: 0 30px;

    .payment-name {
      font-size: 16px;
      font-weight: 700;
      line-height: 23px;
    }

    .payment-rate {
      font-size: 12px;
    }
  }

  .payment-status {
    line-height: 24px;

    .payment-status-image {
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }

    .status-text {
      font-size: 14px;
      color: #2aa76c;
      font-weight: 700;
      margin-left: 10px;
    }
  }
}
</style>
